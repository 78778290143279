import { parseISO } from "date-fns";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";

import {
  BlogCard,
  Button,
  Column,
  CurvedSectionDivider,
  CurvedSectionDividerGlow,
  FormattedText,
  Gain,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  Line,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionCustomerLogos,
  SectionQuotes,
  SimpleHero,
  Text,
  WrappingLogos,
} from "../components";
import { Dimen } from "../constants";

type Props = {
  data: GatsbyTypes.HomePageQuery;
};

export const query = graphql`
  query HomePage {
    prismicHomeV2Page {
      data {
        hero_title
        hero_paragraph
        hero_contact_button_label
        hero_try_now_button_label
        hero_calculator_button_label
        hero_image {
          alt
          dimensions {
            width
            height
          }
          url
        }

        partners_title

        partners_button_label
        globe_title
        globe_content {
          raw
        }
        globe_button_label

        gains {
          gain_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
          gain_pre_title
          gain_title
          gain_content {
            raw
          }
          gain_button_label
          gain_internal_link
        }

        quotes_button_label

        integrations_title
        integrations_paragraph {
          raw
        }
        integrations {
          integration_logo {
            alt
            dimensions {
              width
              height
            }
            url
          }
        }
        integrations_button_label

        blog_title
        blog_paragraph {
          raw
        }

        logistics_cards {
          logistic_card_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
          logistic_card_title
          logistic_card_read_time
          logistic_card_date_picker
          logistic_card_description
          logistic_card_link
        }

        blog_button_label

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function HomePage({ data }: Props) {
  const page = data.prismicHomeV2Page?.data;
  if (!page) throw new Error("HomePage: page missing");

  const integrationLogos = page.integrations
    ?.map((inter) => inter?.integration_logo)
    .filter((p) => p?.url);
  return (
    <Layout showDemoBanner>
      <HelmetSeo {...page} />

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <SimpleHero
            title={page.hero_title}
            description={page.hero_paragraph}
            contactButtonLabel={page.hero_contact_button_label}
            tryNowButtonlabel={page.hero_try_now_button_label}
            calculatorButtonLabel={page.hero_calculator_button_label}
          />

          <Gutter size={4} md={2} />
          <StyledLandingReport>
            <img
              src={require("../../img/pages/home/illustration_landing_report.svg")}
              alt="Sample Shipamax report"
            />
          </StyledLandingReport>
        </PageMaxWidth>
      </Section>

      <StyledNegativeCurve>
        <CurvedSectionDividerGlow
          position="top"
          glowColor="orange"
          backColor="light1"
        />
      </StyledNegativeCurve>

      <Section background="dark" tint="light">
        <StyledGlobeBackground>
          <SectionCustomerLogos
            background="dark"
            title={page.partners_title}
            buttonLabel={page.partners_button_label}
            gutterSize={3}
            maxWidth={560}
          />
          <PageMaxWidth>
            <Gutter size={1} />
            <Line color="orange1" />
            <Gutter size={4} />
            <Grid>
              <Column size={6}>
                <Text typeface="h3" as="h3" bold>
                  {page.globe_title}
                </Text>
                <Gutter />
                <FormattedText>
                  <RichText render={page.globe_content?.raw} />
                </FormattedText>
                <Gutter size={3} />

                <HorizontalAlign align="flex-start">
                  <Button
                    colorTheme="hollow-white"
                    label={page.globe_button_label}
                    to="/why-shipamax"
                  />
                </HorizontalAlign>
              </Column>
            </Grid>
          </PageMaxWidth>
        </StyledGlobeBackground>
      </Section>

      <Section>
        <PageMaxWidth>
          <Gutter size={6} md={2.5} />
          {page.gains?.map(
            (gain, i) =>
              gain && (
                <React.Fragment key={i}>
                  <Gain
                    reverseColumns={i % 2 === 0}
                    preTitle={gain.gain_pre_title}
                    title={gain.gain_title}
                    content={gain.gain_content}
                    image={gain.gain_image}
                    buttonLabel={gain.gain_button_label}
                    linkTo={gain.gain_internal_link}
                  />
                  <Gutter size={6} md={2.5} />
                </React.Fragment>
              )
          )}
        </PageMaxWidth>
      </Section>

      <CurvedSectionDivider
        position="top"
        frontColor="newDark2"
        backColor="light1"
      />
      <SectionQuotes buttonLabel={page.quotes_button_label} />
      <Section background="dark" tint="light">
        <PageMaxWidth>
          <Gutter size={4} />

          <Line color="orange1" />
          <Gutter size={3} />
          <MaxWidth width={910}>
            <Text typeface="h3" as="h2" bold align="center">
              {page.integrations_title}
            </Text>
            <Gutter />
            <FormattedText baseTypeface="h5" textAlign="center">
              <RichText render={page.integrations_paragraph?.raw} />
            </FormattedText>
          </MaxWidth>
          <Gutter size={1.5} />
          <WrappingLogos logos={integrationLogos as any} />
          <Gutter size={1.5} />
          <HorizontalAlign align="center">
            <Button
              colorTheme="hollow-white"
              label="Explore Integrations"
              to="/technology-integrations"
            />
          </HorizontalAlign>
          <Gutter />
        </PageMaxWidth>
      </Section>
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light1"
      />

      <Section>
        <PageMaxWidth>
          <Gutter size={5} />
          <MaxWidth width={910}>
            <Text typeface="h3" as="h2" bold align="center">
              {page.blog_title}
            </Text>
            <Gutter />
            <FormattedText baseTypeface="h5" textAlign="center">
              <RichText render={page.blog_paragraph?.raw} />
            </FormattedText>
          </MaxWidth>
          <Gutter size={1.5} />

          <Grid>
            {page.logistics_cards?.map(
              (lc) =>
                lc && (
                  <Column size={4} key={lc.logistic_card_title}>
                    <BlogCard
                      image={lc.logistic_card_image}
                      to={lc.logistic_card_link ?? "/"}
                      date={
                        lc.logistic_card_date_picker
                          ? parseISO(lc.logistic_card_date_picker)
                          : undefined
                      }
                      title={lc.logistic_card_title}
                      readTime={lc.logistic_card_read_time}
                      description={lc.logistic_card_description}
                    />
                  </Column>
                )
            )}
          </Grid>

          <Gutter size={2.5} />
          <HorizontalAlign align="center">
            <Button
              colorTheme="blue"
              label="Explore Shipamax Blog"
              to="/blog"
            />
          </HorizontalAlign>
          <Gutter size={4} />
        </PageMaxWidth>
      </Section>
    </Layout>
  );
}

const StyledLandingReport = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    max-width: 800px;
    height: 420px;

    @media (max-width: 760px) {
      height: auto;
    }
  }
`;

const StyledNegativeCurve = styled.div`
  margin-top: -260px;

  @media (max-width: ${Dimen.breakpointDownSm}px) {
    margin-top: -160px;
  }
`;

const StyledGlobeBackground = styled.div`
  background-image: url(${require("../../img/pages/home/globe_bg_desktop.jpg")});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  padding-bottom: 150px;

  @media (max-width: ${Dimen.breakpointDownSm}px) {
    background-image: url(${require("../../img/pages/home/globe_bg_mobile.jpg")});
    padding-bottom: 160px;
  }
`;
